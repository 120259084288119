import React, { useEffect } from 'react'
import Footer from '../../Homepage/Footer';
import Navbar from '../../Homepage/Navbar';
import { useMediaQuery } from '@react-hook/media-query';
function Infertility() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const isMediumScreen = useMediaQuery("(min-width : 1024px)")
    if (isMediumScreen) {
        return (
            <div className=' w-full h-auto min-h-screen '>
                <Navbar />
                <div className=' w-[800px] min-h-screen h-auto mx-auto py-14 pt-32
                 '>
                    <div>
                        <h1 className=' uppercase text-3xl text-center text-orange-500 font-medium'>Infertility</h1>
                    </div>
                    <div>
                        <h1 className=' mt-10 font-medium'>
                            The conclusion of fruitlessness is frequently extremely overpowering for everybody.
                            There are an entrenched community for regenerative focus that offers master care and
                            consistent help to couples looking for ripeness treatment. The focuses are well-prepared
                            to deal with complex fruitfulness challenges with a profoundly qualified group of
                            clinicians and cutting-edge foundation and innovation. And furthermore, they work
                            intimately with scholarly clinicians to guarantee that we keep on driving the field of
                            conceptive medication.

                        </h1>
                    </div>

                </div>
                <Footer />
            </div>
        )
    }
    return (
        <div className=' w-full h-full min-h-screen   '>
<Navbar />
            <div className=' text-2xl mt-10'>
                <h1 className=' uppercase text-center font-medium text-orange-500'>Infertility</h1>
            </div>
            <div className=' px-5 mt-10 text-sm my-10 font-medium'>
                <h1>
                    The conclusion of fruitlessness is frequently extremely overpowering for everybody.
                    There are an entrenched community for regenerative focus that offers master care and
                    consistent help to couples looking for ripeness treatment. The focuses are well-prepared
                    to deal with complex fruitfulness challenges with a profoundly qualified group of
                    clinicians and cutting-edge foundation and innovation. And furthermore, they work
                    intimately with scholarly clinicians to guarantee that we keep on driving the field of
                    conceptive medication.




                </h1>
            </div>
            <Footer />
        </div>
    )
}

export default Infertility